import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Auth.css';

const Auth = ({ onLogin }) => {
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [date, setDate] = useState(localStorage.getItem('birthday') || '');
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    birthday: '',
  });
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isSignUp, setIsSignUp] = useState(true); // State to toggle between signup and login

  const splitString = (str) => {
    const firstTen = str.slice(0, 10);
    const splitStr = firstTen.split('-');
    return splitStr;
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/check-auth`, {
            headers: {
              Authorization: token,
            },
          });
          if (!response.data.userId) {
            setToken(null);
            localStorage.removeItem('token');
          }
        } catch (error) {
          setToken(null);
          localStorage.removeItem('token');
        }
      }
    };

    checkAuth();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/signup`, formData);
      setToken(response.data.token);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', formData.username);
      localStorage.setItem('birthday', formData.birthday);
      setFormData({ username: '', email: '', password: '', birthday: '' });
      setDate(formData.birthday);
      setUsername(formData.username);
      onLogin({ name: formData.username, birthday: formData.birthday });
    } catch (error) {
      alert('Error signing up');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/login`, {
        email: formData.email,
        password: formData.password,
      });
      setUsername(response.data.username);
      setDate(response.data.birthday);
      setToken(response.data.token);
      localStorage.setItem('username', response.data.username);
      localStorage.setItem('birthday', response.data.birthday);
      localStorage.setItem('token', response.data.token);
      onLogin({ name: response.data.username, birthday: response.data.birthday });
      setFormData({ email: '', password: '' });
    } catch (error) {
      alert('Error logging in');
    }
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  return (
    <div className="container">
      <div className="c1">
        <h1>Visualize your life!</h1>
        <p>
          Reflect on the value of your time with our life visualization tool. See the months of your life unfold, based on your life expectancy, and discover how activities like phone use, sleep, and work impact your time. Our goal is to inspire more meaningful choices by making time tangible. Life is short—start visualizing today and make the most of every moment!
        </p>
      </div>
      
        {!token ? (
          <div className="c2">
            <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
            {isSignUp ? (
              <form onSubmit={handleSignup} className="form">
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                  className="input"
                  />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input"
                  />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  className="input"
                />
                <input
                  type="date"
                  name="birthday"
                  placeholder="Birthday"
                  value={formData.birthday}
                  onChange={handleChange}
                  className="input"
                  />
                <button type="submit" className="buttona">Sign Up</button>
                <p className="toggle-text">
                  Already have an account?{' '}
                  <span className="toggle-link" onClick={() => setIsSignUp(false)}>Login</span>
                </p>
              </form>
            ) : (
              <form onSubmit={handleLogin} className="form">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input"
                  />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  className="input"
                />
                <button type="submit" className="buttona">Login</button>
                <p className="toggle-text">
                  Don't have an account?{' '}
                  <span className="toggle-link" onClick={() => setIsSignUp(true)}>Sign Up</span>
                </p>
              </form>
            )}
          </div>
        ) : (
          <div>
            <h2>{date}</h2>
            <h2>{username}</h2>
            <button onClick={handleLogout} className="button">Logout</button>
          </div>
        )}
      </div>

  );
};

export default Auth;
