import React, { useState } from 'react';
import Auth from '../components/Auth';
import Grid from '../components/Grid';

export default function Main() {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [user, setUser] = useState(storedUser || null);

  const handleLogin = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    setToken(null);
    localStorage.removeItem('token');
  };

  return (
    <div >

      {!user ? (
        <Auth onLogin={handleLogin} />
      ) : (
        <Grid user={user} onLogout={handleLogout} />
      )}
    </div>
  );
}
