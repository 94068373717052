import React, { useState , useEffect} from 'react';
import "./Grid.css";
import exitImg from "./exit.png";
import infoImg from "./info.png";
// import { Image } from 'react-bootstrap';

const Grid = ({ user, onLogout }) => {
  const [blocks, setBlocks] = useState([]);
  const [birthdayBlocks, setBirthdayBlocks] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [sleeps, setSleeps] = useState(0);
  const [phones, setPhones] = useState(0);
  const [expectancy, setExpectancy] = useState(80);
  const [mview, setMView] = useState([12, 1]);
  const [darkMode, setDarkMode] = useState(true);
  const [age, setAge] = useState(0);
  const [nonupage, setNonupage] = useState(0);
  const [info, setInfo] = useState(false);



  /**
   * Adjusts the distribution of blocks with auto-fill to match the totalBlocks.
   * It first calculates the missing value to make the sum of blocks equal 24,
   * then filters out zero-value blocks and distributes the total blocks proportionally.
   * The remaining blocks after flooring are added to the last non-zero block to balance the total.
   * @param {number[]} blocks The initial blocks to distribute.
   * @param {number} totalBlocks The total number of blocks to distribute.
   * @return {number[]} The adjusted blocks with the sum equal to totalBlocks.
   */
  const adjustDistributionWithAutoFill = (blocks, totalBlocks) => {
    const initialSum = blocks.reduce((acc, val) => acc + val, 0);

    const missingValue = 24 - initialSum;
    const adjustedBlocks = [...blocks];
  
    if (missingValue > 0) {
      adjustedBlocks.push(missingValue);
    }
  
    const nonZeroBlocks = adjustedBlocks.filter(block => block > 0);
    const sumOfNonZeroBlocks = nonZeroBlocks.reduce((acc, val) => acc + val, 0);
  
    const ratio = totalBlocks / sumOfNonZeroBlocks;
  
    const result = adjustedBlocks.map(block => (block > 0 ? Math.floor(block * ratio) : 0));
  
    const adjustedSum = result.reduce((acc, val) => acc + val, 0);
    const remainder = totalBlocks - adjustedSum;
  
    for (let i = result.length - 1; i >= 0; i--) {
      if (result[i] > 0) {
        result[i] += remainder;
        break;
      }
    }
  
    return result;
  };

  
  
  
  const setTry = () => {
    // const blocks = [5, 11, 8]; 
    // const totalBlocks = 100; 
    // console.log(user.birthday)
    const datestring = user.birthday;
    const dateArr = datestring.split("-").map(el => Number(el.split("T")[0]));
    // console.log(dateArr)
    // const result = adjustDistributionWithAutoFill(blocks, totalBlocks);
    // console.log(result); 
  };

  const onInfo = () => {
    setInfo(!info);
  };


  const birthdayCalc = () => {
    const datestring = user.birthday;
    const dateArr = datestring.split("-").map(el => Number(el.split("T")[0]));
    const d = new Date();
    const m = d.getMonth() + 1;
    const currentYear = d.getFullYear();
    const total = Math.floor((currentYear - dateArr[0]) * mview[0] + (m - dateArr[1]) / mview[1]);
    setBirthdayBlocks(total);

    const newBlocks = Array.from({ length: total }, (_, index) => ({
      id: index + 1,
      color: '#333333',
    }));
    setBlocks(newBlocks);
    return newBlocks;
  };

  const ageCalc = () => {
    const now = new Date();
    const datestring = user.birthday;
    const dateArr = datestring.split("-").map(el => Number(el.split("T")[0]));
    const birthDate = new Date(dateArr[0], dateArr[1], dateArr[2]); // Example birthday: July 9, 2001
    const diff = now - birthDate;
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    // setBirthdayBlocks(total);

    // const newBlocks = Array.from({ length: total }, (_, index) => ({
    //   // id: index + 1,
    //   color: '#181818',
    // }));
    // setBlocks(newBlocks);
    // return newBlocks;
  };
  /**
   * Generate an array of blocks for a given amount of time.
   * @param {array} prevArr - The array of blocks to add to.
   * @param {string} col - The color of the blocks to add.
   * @param {int} ite - The amount of time to generate blocks for.
   * @returns {array} The array of blocks with the new blocks added.
   */
  const genCalc = (prevArr, col, ite) => {
    
    const newArr = Array.from({ length: ite }, (_, index) => ({
      id: prevArr.length + index + 1,
      color: col,
    }));
    setBlocks((prevBlocks) => [...prevBlocks, ...newArr]);
    return [...prevArr, ...newArr];
  };

  /**
   * Calculate the remaining blocks until the expectancy age and add them to the
   * blocks array.
   * @param {array} tot - The array of blocks to add the remaining blocks to.
   */
  const expectancyCalc = (tot) => {
    const remainingBlocks = Array.from({ length: (expectancy * mview[0]) - tot.length }, (_, index) => ({
      id: tot.length + index + 1,
      color: '#FFFECB',
    }));
    setBlocks((prevBlocks) => [...prevBlocks, ...remainingBlocks]);
  };

//   const addBlocks = (amount) => {
//     const newBlocks = Array.from({ length: amount }, (_, index) => ({
//       id: blocks.length + index + 1,
//     //   color: 'FFFfff',
//     }));
//     setBlocks([...blocks, ...newBlocks]);
//   };


const displayBlocks = () => {
  return blocks.map((block) => (
    <div key={block.id} className = "block" style={{ backgroundColor: block.color }}>
      {/* {block.id} */}
    </div>
  ));
};

const fullCalc = (list) => {

  var bday = birthdayCalc();
  const result = adjustDistributionWithAutoFill(list, expectancy * mview[0] - birthdayBlocks);
  // console.log(bday);
  // console.log(result);
  if (list[0] > 0) {
    bday = genCalc(bday, '#5448C8', result[0]);
  }
  if (list[1] > 0) {
    bday = genCalc(bday, '#FEA82F', result[1]);
  }
  if (list[2] > 0) {
    bday = genCalc(bday, '#FF2E00', result[2]);
  }
  if (result.length !== 3) {
    expectancyCalc(bday);
  }
};

const updateAge = () => {
  const now = new Date();
  const datestring = user.birthday;
  const dateArr = datestring.split("-").map(el => Number(el.split("T")[0]));
  const birthDate = new Date(dateArr[0], dateArr[1], dateArr[2]); // Example birthday: July 9, 2001
  const diff = now - birthDate;
  const ageInYears = diff / (1000 * 60 * 60 * 24 * 365.25);
  setAge(ageInYears.toFixed(9)); // Update age every second
};

useEffect(() => {
  const temp = birthdayCalc();
  setNonupage(ageCalc());
  expectancyCalc(temp);
  const interval = setInterval(() => updateAge(), 100); // Update age every second
  return () => clearInterval(interval);
}, []);

return (
  <div className='appis' >
    <div className='fixer'>
      <div className='heading'>
        <h1>Welcome, {user.name}</h1>
        {/* <button onClick={onLogout} className='button'> */}
          <img onClick={onLogout} className='button' src={exitImg} alt="Logout" />
        {/* </button> */}
      </div>
      <h2>Your currently {age} years old</h2>
      {/* <button onClick={() => setDarkMode(!darkMode)} style={styles.button}>
        Toggle {darkMode ? 'Light' : 'Dark'} Mode
        </button>
        <button onClick={() => setTry()} style={styles.button}>
        Toggle
        </button> */}
      <div className='mover'>
        {/* <div>
          <label>Monthly</label>
          <input type="radio" name="view" value={[12, 1]} checked={mview[1] === 1} onChange={(e) => setMView([12, 1])} />
          <label>Quarterly</label>
          <input type="radio" name="view" value={[4, 4]} checked={mview[1] === 4} onChange={(e) => setMView([4, 4])} />
          <label>Half a Year</label>
          <input type="radio" name="view" value={[6, 3]} checked={mview[1] === 3} onChange={(e) => setMView([6, 3])} />
          </div> */}
        <div className='slider'>
          <label>Pursuit: {toilets}</label>
          <input type="range" min="0" max={24 - phones - sleeps} value={toilets} onChange={(e) => {setToilets(e.target.valueAsNumber); fullCalc([e.target.valueAsNumber, phones, sleeps]);}} />
        </div>
        <div className='slider'>
          <label>Sleep: {sleeps}</label>
          <input type="range" min="0" max={24 - phones - toilets} value={sleeps} onChange={(e) => {setSleeps(e.target.valueAsNumber); fullCalc([toilets, e.target.valueAsNumber, phones]);}} />
        </div>
        <div className='slider'>
          <label>Phone: {phones}</label>
          <input type="range" min="0" max={24 - toilets - sleeps} value={phones} onChange={(e) => {setPhones(e.target.valueAsNumber); fullCalc([toilets, sleeps, e.target.valueAsNumber]);}} />
        </div>
        <div className='slider'>
          <label>Expectancy: {expectancy}</label>
          <input type="range" min={nonupage} max="100" value={expectancy} onChange={(e) => {setExpectancy(e.target.valueAsNumber); fullCalc([toilets, sleeps, phones]);}} />
        </div>
          <img onClick={onInfo} className='button2' src={infoImg} alt="Info" />
      </div>
      {info && 
      (<div className='info'>
        <p > Visualize your life in months, each block representing one month lived or to be lived depending on the time expectency you specify through the slider above.
        Use the sliders to see how different activities consume time as specified below.</p>
        <div className='ospan'>
          <span>
            <div style={{backgroundColor: '#333333'}} className='block'></div>
            <p clas sName='pofi'>This block represents the months of your life that have alreay passed</p>
          </span>
          <span>
            <div style={{backgroundColor: '#FF2E00'}} className='block'></div>
            <p className='pofi'>This block represents the months of your life that you will spend doing different pursuit such as work, school, lunch, using restroom etc... depending on how many hours they take up of your day</p>
          </span>
          <span>
            <div style={{backgroundColor: '#FEA82F'}} className='block'></div>
            <p className='pofi'>This block represents months of your life that you will spend sleeping depending on how many hours you sleep per day</p>
          </span>
          <span>
            <div style={{backgroundColor: '#5448C8'}} className='block'></div>
            <p className='pofi'>This block represents months of your life that you will spend on your phone depending on how many hours you spend on your phone per day.</p>
          </span>
          <span>
            <div style={{backgroundColor: '#FFFECB'}} className='block'></div>
            <p className='pofi'>This block represents how many free month of free time you have at the rest of your life minus the time it takes for the other activites.</p>
          </span>
        </div>
      </div>)
      }
      {/* <button onClick={() => fullCalc()} style={styles.button}>Refresh</button> */}
      <div className='gridContainer'>
        <div className='grid'>
          {displayBlocks()}
        </div>
    </div>
    </div>

  </div>
);
};

// const styles = {
// app: {
//   fontFamily: 'Arial, sans-serif',
//   textAlign: 'center',
//   minHeight: '100vh',
//   padding: '20px',
// },
// gridContainer: {
//   display: 'flex',
//   justifyContent: 'center',
// },
// grid: {
//   display: 'grid',
//   gridTemplateColumns: 'repeat(36, 1fr)',
//   gap: '5px',
//   maxWidth: '90vw',
//   margin: '0 auto',
// },
// block: {
//   // backgroundColor: '#007BFF',
//   borderRadius: '5px',
//   color: 'brown',
//   fontSize: '0.8rem',
//   // color: '#fff',
//   height: '1.4rem',
//   width: '1.4rem',
//   textAlign: 'center',
//   // border: '1px solid black',
// },
// button: {
//   padding: '10px 20px',
//   backgroundColor: '#007BFF',
//   color: '#fff',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
// },
// };

export default Grid;
